import React from "react"
import { Link, graphql } from "gatsby"
import Swiper from "react-id-swiper"
import Header from "../components/header"
var slugify = require("../helpers")

export default ({ data, pageContext }) => {
  const Slides = data.allVoorstellingenResults.edges.map(voorstelling => (
    <div key={voorstelling.node.id}>
      <Link to={"/fr/spectacle/" + slugify(voorstelling.node.title_fr)}>
        <img
          src={
            "https://www.circusronaldo.be/uploads/" +
            voorstelling.node.mobile_image
          }
          alt=""
        />
      </Link>
    </div>
  ))
  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }
  return (
    <div>
      <Header back="true"  backLink="/fr" taal={pageContext.langKey}  />
      <div>
        <h1>Spectacles</h1>
        <Swiper {...params}>{Slides}</Swiper>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allVoorstellingenResults {
      edges {
        node {
          id
          title_fr
          mobile_image
        }
      }
    }
  }
`
